@tailwind base;
@tailwind components;
@tailwind utilities;

/*------------------------SECTION Variables-------------------------*/
:root {
  --dark: #333;
  --color_1: #2a5a9b;
  --accent_1: #da4450;
  --accent_2: #31ac5a;

  --border-radius: 5px;
  --shadow: 0px 0px 15px -5px #aaa;
  --default-animation-delay: 0.3s;
}

/*-----------!SECTION----------*/
* {
  box-sizing: border-box;
}
/*-------------------SECTION Tooltips-------------------*/
/* Tooltip container */
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 240px;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  font-size: 0.9rem;
  line-height: 1rem;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 9999;

  left: 105%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
/*------------!SECTION---------------*/

/*----------------------SECTION Number Inputs------------------------------*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/*!SECTION*/

/*----------------------SECTION Scrollbar------------------------------*/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*-----!SECTION*/

/*--------------SECTION Utility Classes*/
.bg-color_1 {
  background-color: var(--color_1);
}

.flex-center {
  @apply flex items-center justify-center;
}

.blue_btn {
  @apply ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md
  text-white bg-blue-600 hover:bg-blue-700 focus:outline-none
  focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50;
}
/*----------!SECTION--------*/

.options_bar_icon {
  @apply flex-center h-14;
}

.fill_container {
  width: 100vw;
  height: calc(100vh - 4rem);
}

@media (min-width: 768px) { 
  .fill_container {
    width: calc(100vw - 4rem);
    height: 100vh;
  }
}

.table_header {
  top: 0;
  z-index: 2;
  position: sticky;
  background-color: white;

  @apply px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-full;
}

.tag {
  @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full w-16 flex-center;
}

.small_tag {
  @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full flex-center;
}

@media (min-width: 768px) { 
  .show_mobile {
    display: none;
  }
}

@media (max-width: 767px) { 
  .hide_mobile {
    display: none;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 4px solid #dedede;
  border-top: 4px solid rgb(147, 197, 253);
  animation: rotate 1.2s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
}






/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;

}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}


/*Show Unsynced border*/
.unsynced {
  border-left: 4px solid #ff3535 !important;
}